import React from 'react';

export default ({ cdn, intl }: { cdn: string; intl: any }) => {
  const altText = intl.formatMessage({
    id: 'TRUSTE_IMG_ALT',
    defaultMessage: "Trust's online privacy certification",
  });

  return (
    <a
      href="//privacy.truste.com/privacy-seal/validation?rid=6218d0ea-ee8b-4472-87dd-ba4bf95aab3d"
      title="TRUSTe online privacy certification"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={`${cdn}/truste.svg`} alt={altText} />
    </a>
  );
};
