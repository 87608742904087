import cn from 'classnames';
import React, { useId } from 'react';

import Modal from 'components/Core/Modal/Modal';
import { Close } from 'components/Shared/Icons/Icons';
import { colors, fontSize, breakpoints } from 'styles/cp';

export interface Props {
  hide(event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void;
  header?: React.ReactNode;
  children?: React.ReactNode;
  redesign?: boolean;
  removeDefaultBackground?: boolean;
  customDesignKey?: string;
  isCloseVisible?: boolean;
  className?: string;
  headerClassName?: string;
  overlayClassName?: string;
  backgroundColor?: string;
  width?: string;
  height?: string;
  padding?: string;
  closeIconPosition?: { right?: string; top?: string };
}

const RoundedModal: React.FC<Props> = ({
  hide,
  children,
  header,
  redesign,
  removeDefaultBackground,
  customDesignKey,
  isCloseVisible = true,
  className,
  overlayClassName,
  headerClassName,
  backgroundColor,
  width,
  height,
  padding,
  closeIconPosition,
}) => {
  const headerId = useId(); // Generate a unique ID

  return (
    <Modal
      className={overlayClassName}
      removeDefaultBackground={removeDefaultBackground}
      ariaLabelledBy={header ? headerId : undefined}
    >
      <div
        className={cn('rounded-modal-container', className, {
          'freetext-rounded-modal-container': customDesignKey === 'freeTextModal',
          'postinvoice-rounded-modal-container': customDesignKey === 'postInvoiceModal',
          redesign,
        })}
        data-testid="test-rounded-modal"
      >
        <span className={cn('rounded-modal-header', headerClassName)}>
          {header && (
            <h1 id={headerId} className="rounded-modal-message">
              {header}
            </h1>
          )}
          {isCloseVisible && (
            <span
              className="close-icon"
              onClick={hide}
              data-cy="rounded-modal-btn-close"
              data-testid="rounded-modal-btn-close"
            >
              <Close />
            </span>
          )}
        </span>
        {children}
      </div>
      {/* language=scss */}
      <style jsx>{`
        .rounded-modal-container {
          max-width: 100%;
          background-color: ${backgroundColor ?? colors.white};
          width: ${width ?? 'auto'};
          height: ${height ?? 'auto'};
          padding: ${padding ?? '24px 40px'};
          /* @growth_exp - freetext */
          border-radius: 4px;
          border: solid 1px ${colors.whiteGray};
          display: block;
          text-align: center;

          @media screen and (max-width: ${breakpoints.sm}) {
            padding: 12px 24px 32px;
          }
        }

        .freetext-rounded-modal-container {
          border-radius: 12px;
        }

        .postinvoice-rounded-modal-container {
          border-radius: 8px;
          width: 752px;
        }

        .redesign {
          margin: 16px;
          width: 100%;
          height: 92%;
          position: absolute;
          bottom: 0px;
          margin: auto;
          border-top-right-radius: 30px;
          border-top-left-radius: 30px;
        }

        .rounded-modal-header {
          position: relative;
          display: flex;
          text-align: left;

          @media screen and (max-width: ${breakpoints.sm}) {
            margin-bottom: 16px;
          }
        }

        .close-icon {
          position: absolute;
          right: ${closeIconPosition?.right ?? '-16px'};
          top: ${closeIconPosition?.top ?? 'unset'};
          cursor: pointer;
          z-index: 3;

          @media screen and (max-width: ${breakpoints.sm}) {
            right: -8px;
          }
        }

        .rounded-modal-message {
          font-size: ${fontSize.ml};
          font-family: AvenirNextforINTUIT-Medium;
          padding-top: 16px;
          margin-top: 0;
          margin-bottom: 0;
          display: inline;
          @media screen and (max-width: ${breakpoints.sm}) {
            font-size: ${fontSize.ms};
            padding-top: 32px;
          }
        }
      `}</style>
    </Modal>
  );
};

export default RoundedModal;
